import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useSiteMetadata } from "../hooks/defaults/Use-SiteMetadata";
import QuickInfo from "../components/includes/quickInfo";
import Seo from "../components/seo";
import WhereWeAre from "../components/includes/where_we_are";
import Layout from "../components/Defaults/Layout";

import Jotform from "react-jotform";
import Cta from "../components/CTA/CTAMain";

const SecondPage = () => {
	const { googleMap, jotForm } = useSiteMetadata();
	const data = useStaticQuery(graphql`
		query SiteTitleQuerys {
			site {
				siteMetadata {
					title
					googleMap

					# gMap
					# mainArea
				}
			}
		}
	`);
	return (
		<>
			<Layout>
				{/* <CTA /> */}
				<section className="mt-10 mx-auto  max-w-screen-xl items-center mb-20 justify-between  ">
					<div className="grid grid-cols-1 md:grid-cols-12 gap-8">
						<div className="col-span-1 md:col-span-9">
							<Cta
								Heading="Contact Us Today"
								subHeading="We are here to help you with any questions you may
													have"
								callNow={true}
							/>
							<div className="md:p-0 p-4 mt-6">
								<Jotform
									className=" text-2xl"
									style={{
										width: "100%",
										height: "100%",
										border: "none",
										"background-color": "transparent",
									}}
									src={jotForm}
								/>
							</div>
						</div>
						<div className="col-span-1 md:col-span-3 p-4 md:p-0 ">
							<QuickInfo />
						</div>
					</div>
					<WhereWeAre gMap={googleMap ? googleMap : ""} area="Surey, UK" />{" "}
				</section>
			</Layout>
		</>
	);
};

export const Head = () => <Seo title="Get a Quote" />;

export default SecondPage;
